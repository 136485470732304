<template>
  <div class="main-page" id="main-page">
    <div class="main-page-header">
      <div class="main-page-header-left">
        <img src="../assets/home/logo-fly.png" alt="">
      </div>
      <div class="main-page-header-right">
        <div><a href="#main-page">首页</a></div>
        <div><a href="#home-des">飞村APP</a></div>
        <div><a href="#home-industry">行业背景</a></div>
        <div><a href="#home-location">使命定位</a></div>
        <div><a href="#main-page-footter">联系我们</a></div>
      </div>
    </div>
    <div class="main-page-content">
      <router-view></router-view>
    </div>
    <div class="main-page-footter" id="main-page-footter">
      <div class="main-page-footter-up">
        <div class="main-page-footter-up-menu">置顶首页</div>
        <div class="main-page-footter-up-menu">法律声明</div>
        <div class="main-page-footter-up-menu">隐私条款</div>
        <div class="main-page-footter-up-menu">联系我们</div>
        <div class="main-page-footter-up-img">
          <div class="main-page-footter-up-img-item">
            <div class="main-page-footter-up-img-app"></div>
            <p>下载飞村APP</p>
          </div>
          <div class="main-page-footter-up-img-item" style="margin-left: 37px">
            <div class="main-page-footter-up-img-wechat"></div>
            <p>关注公众号</p>
          </div>
        </div>
      </div>
      <div class="main-page-footter-down">
        <div><a href="https://xyt.xcc.cn/getpcInfo?sn=1535164108263890944&language=CN&certType=8&url=*.tokenbty.com" target="_blank" style="position: relative;display: inline-block;height: 38px;">
      <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1535164108263890944=*.tokenbty.com.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a></div>
          <div>© 添崖生态技术有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备2020032634号-4</a></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
.main-page {
  a{
    text-decoration:none;
    }
  &-header {
    height: 80px;
    line-height: 80px;
    box-sizing: border-box;
    padding: 0 15%;
    display: flex;
    justify-content: space-between;

    &-left {
      img {
        height: 50px;
        width: 125px;
        margin-top: 17px;
      }
    }

    &-right {
      display: flex;
      div {
        margin-left: 70px;
        font-size: 20px;
        color: #121212;
        font-weight: 500;
        a:link {color: #121212}
        a:visited {color: #121212}
        a:hover {color: #121212}
        a:active {color: #121212}
      }
    }
  }
  &-footter{
      height: 230px;
      box-sizing: border-box;
      &-up{
          height: 96px;
          display: flex;
          background-color: #141620;
          position: relative;
          margin-bottom: 2px;
          &-menu{
                margin-top: 55px;
                margin-left: 5%;
                font-size: 18px;
                font-family: Source Han Sans SC;
                font-weight: 500;
                color: #FFFFFF;
          }
          &-img{
                 display: flex;
                 position: absolute;
                 right: 10%;
                 top:  -50px;
                 z-index: 99;
             &-app{
                 background-color: white;
                 width: 91px;
                 height: 91px;
                 background-image: url('../assets/home/main-page-footter-app.png');
                 background-size: cover;
             }
             &-wechat{
                 width: 91px;
                 height: 91px;
                 background-image: url('../assets/home/main-page-footter-wechat.png');
                 background-size: cover;
             }
             p{
                font-size: 16px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #999999;
                margin: 15px 0 0;
             }
          }
      }
      &-down{
          height: 232px;
          background-color: #141620;
          text-align: center;
          div{
            padding-top: 72px;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            color: #999999;
            line-height: 47px;
            a:link {color: #999999}
            a:visited {color: #999999}
            a:hover {color: #999999}
            a:active {color: #999999}
          }
      }
  }
}
</style>
