import Main from '@/views/Main.vue'

export const routers = [
  {
    path: '/',
    title: '基础页',
    component: Main,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        meta: { title: '首页' },
        component: () => import(/* webpackChunkName: "Home" */ '../views/home')
      }
    ]
  }
]
